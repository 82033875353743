<template>
     <section id="video">

        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="card bg-dark text-white py-4 py-sm-0"><img class="w-100" src="../assets/img/gallery/video.png" alt="video" />
                <div class="card-img-overlay bg-dark-gradient d-flex flex-column flex-center"><img src="../assets/img/icons/play.png" width="80" alt="play" />
                  <h5 class="text-light">Mengapa Sistem Penjaminan Mutu Internal (SPMI) Itu Penting?</h5>
                  <p class="text-center">Tingkatkan mutu perguruan tinggi anda<br class="d-none d-sm-block" />dengan satu kemudahan<br class="d-none d-sm-block" /></p><a class="stretched-link" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"></a>
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                      <div class="modal-content overflow-hidden">
                        <div class="modal-header p-0">
                          <div class="ratio ratio-16x9" id="exampleModalLabel">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/JUEM5rYafj0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

    </section>
</template>

<script>
export default {
    name : 'Video'
}
</script>