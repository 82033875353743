<template>
    <section class="py-7" id="services" container-xl="container-xl" style="background-image:linear-gradient(180deg, #3a3953 0%, #59568f 100%)">

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12 text-center mb-3">
              <h2 class="text-white">Pilih Layanan Terbaik Sesuai Kebutuhan Kampus Anda</h2>
              <h5 class="fw-normal text-white mb-5"> Sewa Layanan penjaminan mutu internal terbaik dengan fitur terlengkap meliputi PPEPP untuk kemudahan Perguruan Tinggi anda memperoleh mutu terbaik!</h5>
            </div>
          </div>
          <div class="row h-100 justify-content-center">
            <!-- <div class="col-md-3 py-5 px-md-2 px-lg-3">
              <div class="card h-100 card-span">
                <div class="card-body d-flex flex-column justify-content-around">
                  <div class="text-center pt-0 pb-2" style="margin-bottom:27px">
                    <h4 class="my-3 text-success">Trial</h4>
                    <span class="text-dark fs-3 fw-bold">Gratis </span><span class="text-normal">/3 Bulan</span>
                  </div>
                  <ul class="list-unstyled mt-0" style="font-size:14px">
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span><i>Unlimited</i> Program Studi
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-times-circle text-danger" ></i></span><s class="text-danger">Custom Domain</s>
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Diskusi & Support Teknis<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Online)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Storage 8 GB
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Setup Data Migrasi<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size:13px">(Syarat & Ketentuan Berlaku)</span>
                    </li>
                    <li class="mb-3">&nbsp;</li>
                  </ul>
                  <div class="text-center mt-2">
                    <div class="d-grid">
                      <!- a v-bind:href="url_service+service.bronze" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a ->
                      <a v-bind:href="url_service+service.trial" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-3 py-5 px-md-2 px-lg-3">
              <div class="card h-100 card-span">
                <div class="card-body d-flex flex-column justify-content-around">
                  <div class="text-center pt-0 pb-2" style="margin-bottom:27px">
                    <h4 class="my-3 text-danger">Bronze</h4>
                    <span class="text-dark fs-3 fw-bold">Rp 1jt </span><span class="text-normal">/Bulan</span>
                  </div>
                  <ul class="list-unstyled mt-0" style="font-size:14px">
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Up to 3 Auditee (Unit<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Penunjang, Fakultas, dan<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Program Studi)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-times-circle text-danger" ></i></span><s class="text-danger">Custom Domain</s>
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Diskusi & Support Teknis<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Online)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Storage 8 GB
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Backup Data
                    </li>
                    <li class="mb-3">&nbsp;<br>&nbsp;</li>
                  </ul>
                  <div class="text-center mt-2">
                    <div class="d-grid">
                      <a v-bind:href="url_service+service.bronze" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a>
                      <!-- <a v-bind:href="url_service" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 py-5 px-md-2 px-lg-3">
              <div class="card h-100 card-span">
                <div class="card-body d-flex flex-column justify-content-around">
                  <div class="text-center pt-0 pb-2" style="margin-bottom:27px">
                    <h4 class="my-3 text-black-50">Silver</h4>
                    <span class="text-dark fs-3 fw-bold">Rp 2jt </span><span class="text-normal">/Bulan</span>
                  </div>
                  <ul class="list-unstyled mt-0" style="font-size:14px">
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Up to 10 Auditee (Unit<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Penunjang, Fakultas, dan<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Program Studi)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>1 Custom Domain<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Subdomain Kampus)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Diskusi & Support Teknis<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Online)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Storage 40 GB
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Backup Data
                    </li>
                    <li class="mb-3">&nbsp;</li>
                  </ul>
                  <div class="text-center mt-2">
                    <div class="d-grid">
                      <a v-bind:href="url_service+service.silver" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 px-md-2 px-lg-3">
              <div class="card h-100 border-warning shadow-warning">
                <div class="card-header pt-3 bg-warning">
                  <div class="card-title text-center"><h5 class="text-white"><i class="fa fa-star"></i> Penawaran Terbaik</h5></div>
                </div>
                <div class="card-body d-flex flex-column justify-content-around">
                  <div class="text-center pt-0 pb-2" style="margin-bottom:27px">
                    <h4 class="my-3 text-warning">Gold</h4>
                    <span class="text-dark fs-3 fw-bold">Rp 2,5jt </span><span class="text-normal">/Bulan</span>
                  </div>
                  <ul class="list-unstyled mt-2" style="font-size:14px">
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Up to 20 Auditee (Unit<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Penunjang, Fakultas, dan<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Program Studi)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>1 Custom Domain<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Subdomain Kampus)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Diskusi & Support Teknis<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Online)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Terintegrasi Aplikasi Existing<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-muted"><i>*Sesuai dengan formulir<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;yang ada di eSPMI</i></span>
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Storage 80 GB
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Backup Data
                    </li>
                    <li class="mb-3">&nbsp;</li>
                  </ul>
                  <div class="text-center mt-2">
                    <div class="d-grid">
                      <a v-bind:href="url_service+service.gold" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 py-5 px-md-2 px-lg-3">
              <div class="card h-100 card-span">
                <div class="card-body d-flex flex-column justify-content-around">
                  <div class="text-center pt-0 pb-2" style="margin-bottom:27px">
                    <h4 class="my-3 text-primary">Platinum</h4>
                    <span class="text-dark fs-3 fw-bold">Rp 4jt </span><span class="text-normal">/Bulan</span>
                  </div>
                  <ul class="list-unstyled mt-0" style="font-size:14px">
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Up to 30 Auditee (Unit<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Penunjang, Fakultas, dan<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Program Studi)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>1 Custom Domain<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Subdomain Kampus)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Diskusi & Support Teknis<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Online)
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Terintegrasi Aplikasi Existing<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-muted"><i>*Sesuai dengan formulir<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;yang ada di eSPMI</i></span>
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Storage 120 GB
                    </li>
                    <li class="mb-3"><span class="me-2"><i class="fas fa-check-circle text-success" ></i></span>Backup Data
                    </li>
                  </ul>
                  <div class="text-center">
                    <div class="d-grid">
                      <a v-bind:href="url_service+service.platinum" target="_blank" class="btn btn-danger btn-lg fw-bold"> Pilih Paket </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-5">
            <div class="col-md-12 col-lg-12 text-center mb-3">
              <h4 class="text-white">Ingin memiliki aplikasi eSPMI? (Full Version)</h4>
            </div>
          </div>
          <div class="row h-100">
            <div class="col-md-12 my-3 mb-md-0">
              <div class="card card-span p-3">
                <div class="card-body">
                    <h5 class="mb-0 text-primary">Nikmati Layanan Terlengkap jamituApp eSPMI</h5>
                    <p class="card-text pt-3">Tentukan kebutuhan Layanan Penjaminan Mutu Anda secara lebih dinamis dan flexibel melalui jamituApp eSPMI <i>Full Version</i>
                      <br>Tidak perlu khawatir masa tenggang & support teknis.</p>
                    <h5 class="py-1">Hubungi Tim Marketing Kami </h5>
                    <a href="tel:081370000475" target="_blank" class="btn btn-warning btn-lg fw-bold px-4"><i class="fa fa-phone"></i> Telepon </a>&nbsp;&nbsp;<a v-bind:href="wa_link" target="_blank" class="btn btn-lg fw-bold btn-success"><img src="../assets/img/gallery/whatsapp.png" alt="..." style="width:24px" /> Whatsapp</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

    </section>
</template>

<script>
export default {
    name : 'Service',
    data(){
      return {
        /*url_service : process.env.VUE_APP_URL_SERVICE+'/services/order?id=',*/
        url_service : process.env.VUE_APP_URL_SERVICE+'/login?rd=',
        service : {
          trial : btoa(process.env.VUE_APP_URL_ORDER+5),
          bronze : btoa(process.env.VUE_APP_URL_ORDER+1),
          silver : btoa(process.env.VUE_APP_URL_ORDER+2),
          gold : btoa(process.env.VUE_APP_URL_ORDER+3),
          platinum :btoa(process.env.VUE_APP_URL_ORDER+4),
        },
        wa_link : 'https://bit.ly/jamituid'
      }
    }
}
</script>