<template>
    <section class="py-xxl-10 py-5 bg-banner" id="home">
        <!--div class="bg-holder bg-size" style="background-image:url(../assets/img/gallery/hero.png);background-position:top center;background-size:cover;">
        </div-->
        <!--/.bg-holder-->

        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 col-xl-6 col-xxl-6 order-0 order-md-1 text-end"><img class="pt-0 pt-md-0 w-100" src="../assets/img/illustrations/hero.png" alt="hero-header" /></div>
            <div class="col-md-6 col-xl-6 col-xxl-6 text-md-start text-center pt-7 pb-8">
              <h1 class="fw-bolder fs-5 fs-xxl-5 text-dark-blue">Permudah pengelolaan<br>Penjaminan Mutu<br>internal anda</h1>
              <p class="fs-1 mb-5">Peningkatan kualitas dari penjaminan mutu internal yang berkelanjutan. </p><a v-bind:href="url_service" class="btn btn-primary btn-outline btn-lg fw-bold" role="button">Coba Sekarang <i class="fas fa-chevron-circle-right"></i></a>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
export default {
    name : 'Banner',
    data(){
      return {
        url_service : process.env.VUE_APP_URL_SERVICE+'/login',
        url_event : process.env.VUE_APP_URL_SERVICE+'/login?event=register'
      }
    }
}
</script>