<template>
  <div class="home">
    <Navbar/>
    <Banner />
    <Feature />
    <Service />
    <Awards />
    <Video />
    <Testimoni />
    <Faq />
    <Contact />
    <!-- Subscribe /-->
    <Link />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Banner from '@/components/Banner.vue'
import Feature from '@/components/Feature.vue'
import Service from '@/components/Service.vue'
import Awards from '@/components/Awards.vue'
import Video from '@/components/Video.vue'
import Testimoni from '@/components/Testimoni.vue'
import Faq from '@/components/Faq.vue'
import Contact from '@/components/Contact.vue'
import Link from '@/components/Link.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Navbar, 
    Banner, 
    Feature, 
    Service, 
    Awards, 
    Video, 
    Testimoni, 
    Faq, 
    Contact, 
    Link, 
    Footer
  }
}
</script>
