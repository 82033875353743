<template>
    <section class="py-7" id="feature" container-xl="container-xl">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10 text-center mb-5">
              <!--h5 class="text-danger">Layanan</h5-->
              <h2>Satu Kemudahan Untuk Mutu Berkelanjutan</h2>
              <h5 class="fw-normal">Fitur terlengkap untuk mengelola dan menentukan <br>penjaminan mutu internal perguruan tinggi anda.</h5>
            </div>
          </div>
          <!-- Feature 01 -->
          <div class="row h-100 justify-content-center mb-3">
            <div class="col-md-6 pt-0 px-md-2 px-lg-3 text-center">
              <img src="../assets/img/gallery/feature-03.png" alt="..."  style="width:300px"/>
            </div>
            <div class="col-md-6 pt-2 px-md-2 px-lg-3">
              <div class="text-left pt-0">
                  <h5 class="my-2">Standar Mutu Dinamis dan Terdokumentasi Dengan Baik </h5>
              </div>
              <p class="mb-1">Memudahkan pendataan indikator standar mutu beserta nilai mutunya yang jumlahnya sangat banyak dan dapat direvisi dengan mudah pada siklus berikutnya sesuai dengan peningkatan standar mutu pada siklus SPMI.</p>
            </div>
          </div>
          <!-- Feature 02 -->
          <div class="row h-100 justify-content-center mb-3">
            <div class="col-md-5 pt-2 px-md-2 px-lg-3">
              <div class="text-left pt-0">
                  <h5 class="my-2">Pelaksanaan Evaluasi Diri Program Studi</h5>
              </div>
              <p class="mb-1">Memudahkan pelaksanaan evaluasi diri program studi berdasarkan indikator standar mutu yang ditetapkan sendiri oleh perguruan tinggi. Pengguna dapat dengan mudah memilih nilai mutu masing-masing indikator dan mengunggah dokumen yang berkaitan dengan indikator tersebut.</p>
            </div>
            <div class="col-md-7 pt-0 px-md-2 px-lg-3 text-center">
              <img src="../assets/img/gallery/feature-01.png" alt="..." style="width:300px" />
            </div>
          </div>
          <!-- Feature 03 -->
          <div class="row h-100 justify-content-center">
            <div class="col-md-6 pt-0 px-md-2 px-lg-3 text-center">
              <img src="../assets/img/gallery/feature-13.png" alt="..." style="width:300px" />
            </div>
            <div class="col-md-6 pt-2 px-md-2 px-lg-3">
              <div class="text-left pt-0">
                  <h5 class="my-2">Audit Mutu Internal (AMI)</h5>
              </div>
              <p class="mb-1">disertai fitur Audit Mutu Internal, kegiatan audit kelengkapan dan juga audit lapangan berdasarkan data yang sudah terekam pada kegiatan evaluasi diri program studi sehingga setiap siklus saling berkaitan dan saling melengkapi. Proses AMI juga disertai fitur untuk membantu auditor membuat catatan pertanyaan pada audit kelengkapan dan meng-upload dokumen terkait bukti-bukti pada audit lapangan.</p>
            </div>
          </div>
          <!-- Feature 04 -->
          <div class="row h-100 justify-content-center">
            <div class="col-md-6 pt-2 px-md-2 px-lg-3">
              <div class="text-left pt-0">
                  <h5 class="my-2">Perhitungan Skor Hasil Evaluasi dan Audit</h5>
              </div>
              <p class="mb-1">Dengan merekam data indikator, evaluasi diri, dan kegiatan audit, maka program studi maupun LPM dapat melihat skor masing-masing program studi dan dapat melihat perkembangan skor tersebut dalam rentang periode tertentu.</p>
            </div>
            <div class="col-md-6 pt-0 px-md-2 px-lg-3 text-center">
              <img src="../assets/img/gallery/feature-11.png" alt="..." style="width:300px" />
            </div>
          </div>
          <!-- Feature 05 -->
          <div class="row h-100 justify-content-center">
            <div class="col-md-6 pt-0 px-md-2 px-lg-3 text-center">
              <img src="../assets/img/gallery/feature-10.png" alt="..." style="width:300px" />
            </div>
            <div class="col-md-6 pt-2 px-md-2 px-lg-3">
              <div class="text-left pt-0">
                  <h5 class="my-2">Mengetahui Track Record Mutu Suatu Prodi/Unit</h5>
              </div>
              <p class="mb-1">Dengan adanya riwayat dari penjaminan mutu dari suatu unit/prodi maka tim Tim Penjaminan Mutu dapat mengetahui track record mutu dari unit/prodi tersebut.</p>
            </div>
          </div>
          <!-- Feature 06 -->
          <div class="row h-100 justify-content-center">
            <div class="col-md-6 pt-2 px-md-2 px-lg-3">
              <div class="text-left pt-0">
                  <h5 class="my-2">Visualisasi Simulasi Akreditasi</h5>
              </div>
              <p class="mb-1">Memberikan kepercayaan diri untuk menghadapi Sistem Penjaminan Mutu Eksternal (SPME) karena memiliki gambaran simulasi setiap periode AMI dan kelengkapan dokumen dukung yang sahih dalam single repository.</p>
            </div>
            <div class="col-md-6 pt-0 px-md-2 px-lg-3 text-center">
              <img src="../assets/img/gallery/feature-07.png" alt="..." style="width:300px" />
            </div>
          </div>
        </div>
        <!-- end of .container-->
    </section>
</template>

<script>
export default {
    name : 'Feature'
}
</script>