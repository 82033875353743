<template>
    <section class="py-2">

        <div class="container-fluid">
          <div class="row flex-center">
            <div class="bg-holder bg-size bg-img-red">
            </div>
            <!--/.bg-holder-->

            <div class="col-md-8 col-lg-5 text-center z-index-1 mt-5">
              <h2 class="text-white py-5">Testimonial</h2>
            </div>
          </div>
          <div class="carousel slide" id="carouselExampleDark" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="10000">
                <div class="row h-100">
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card  min-vh-75 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Testimoni #1</h5>
                        <p class="card-text pt-3">jamitu.id sangat mendukung langkah peningkatan mutu perguruan tinggi secara berkelanjutan dengan alur proses yg jelas dan terkendali. Penyempurnaannya ke arah user friendly application dapat membuat para pejuang mutu lebih menikmati kemudahan dalam mewujudkan budaya mutu institusi. </p>
                      </div>
                      <div class="card-footer bg-white">
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><!--img class="img-fluid" src="../assets/img/icons/avatar.png" alt="" /-->
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Prof. Dr. lr. Musrizal Muin, M.Si.</h6>
                              <p class="fs--2 fw-normal mb-0">Kepala Pusat Penjaminan Mutu Internal UNHAS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card min-vh-75 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Testimoni #2</h5>
                        <p class="card-text pt-3">Penggunaan Jamitu.id sangat membantu unit kerja untuk mematuhi siklus PPEPP. Melalui aplikasi eSPMI capaian kinerja dapat dengan mudah dimonitor sejauh unit kerja mentaati kewajiban.untuk upload data sesuai dengan ketentuan dari instrumen yang dikembangkan dalam eSPMI. Monitoring operasional sistem ini perlu terus dilakukan agar pengguna makin terbiasa, familiar dan mendapatkan manfaat sehingga akan suka rela untuk memanfaatkannya tanpa terpaksa.</p>
                      </div>
                      <div class="card-footer bg-white">
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><!--img class="img-fluid" src="../assets/img/icons/avatar.png" alt="" /-->
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Ibu Insiyah </h6>
                              <p class="fs--2 fw-normal mb-0">Kepala Unit Penjaminan Mutu Poltekkes Kemenkes Surakarta</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card min-vh-75 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Testimoni #3</h5>
                        <p class="card-text pt-3">jamitu.id sangat membantu dalam pemenuhan standar pada satuan pendidikan secara sistemik, holistik, dan berkelanjutan, sehingga tumbuh dan berkembang budaya mutu pada satuan pendidikan secara mandiri. Aplikasinya sangat memudahkan kami dalam mewujudkan budaya mutu Institusi.</p>
                      </div>
                      <div class="card-footer bg-white">
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><!--img class="img-fluid" src="assets/img/icons/avatar.png" alt="" /-->
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Widya Asnita, S.M</h6>
                              <p class="fs--2 fw-normal mb-0">Staff LPMPP UNHAS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="2000">
                <div class="row h-100">
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card min-vh-75 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Testimoni #4</h5>
                        <p class="card-text pt-3">Dengan adanya e-spmi polkesta, sangat membantu dan mempermudah dalam melakukan monitoring dan evaluasi dari segi auditee (prodi) dan auditor internal, khususnya dalam implementasi PPEPP penjaminan mutu di polkesta. Kegiatan AMI juga menjadi lebih konsisten dengan schedule yang sudah direncanakan. </p>
                      </div>
                      <div class="card-footer bg-white">
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><!--img class="img-fluid" src="assets/img/icons/avatar.png" alt="" /-->
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Linda Safira</h6>
                              <p class="fs--2 fw-normal mb-0">Staff Unit Penjaminan Mutu Polkesta</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card min-vh-75 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Testimoni #5</h5>
                        <p class="card-text pt-3">Pengalaman saya selama menggunakan ESPMI, sangat puas karena mulai dari tidak paham penggunaan aplikasi kemudian diajari dengan sabar oleh tim hingga kami bisa menggunakan. pada saat penggunaan, ada hal-hal yang kurang/permasalahan yang dilaporkan melalui WA maka dengan segerah direspon sehingga permasalahan bisa teratasi. tks pak Waskito bersama tim. </p>
                      </div>
                      <div class="card-footer bg-white">
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><!--img class="img-fluid" src="assets/img/icons/avatar.png" alt="" /-->
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Prof.Dr.Ir. Hastang, M.Si</h6>
                              <p class="fs--2 fw-normal mb-0">Tim Auditor Internal UNHAS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--div class="carousel-item">
                <div class="row h-100">
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card h-100 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Fantastic service!</h5>
                        <p class="card-text pt-3">I purchased a phone from an e-commerce site, and this courier service provider assisted me in getting it delivered to my home. I received my phone within one day, and I was really satisfied with their service when I received it. </p>
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><img class="img-fluid" src="assets/img/icons/avatar.png" alt="" />
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Yves Tanguy</h6>
                              <p class="fs--2 fw-normal mb-0">Chief Executive, DLF</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card h-100 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Fantastic service!</h5>
                        <p class="card-text pt-3">“I purchased a phone from an e-commerce site, and this courier service provider assisted me in getting it delivered to my home. I received my phone within one day, and I was really satisfied with their service when I received it. </p>
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><img class="img-fluid" src="assets/img/icons/avatar.png" alt="" />
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Kim Young Jou</h6>
                              <p class="fs--2 fw-normal mb-0">Chief Executive, DLF</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card h-100 card-span p-3">
                      <div class="card-body">
                        <h5 class="mb-0 text-primary">Fantastic service!</h5>
                        <p class="card-text pt-3">I purchased a phone from an e-commerce site, and this courier service provider assisted me in getting it delivered to my home. I received my phone within one day, and I was really satisfied with their service when I received it. .</p>
                        <div class="d-xl-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center mb-3"><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i><i class="fas fa-star text-primary me-1"></i></div>
                          <div class="d-flex align-items-center"><img class="img-fluid" src="assets/img/icons/avatar.png" alt="" />
                            <div class="flex-1 ms-3">
                              <h6 class="mb-0 fs--1 text-1000 fw-medium">Yves Tanguy</h6>
                              <p class="fs--2 fw-normal mb-0">Chief Executive, DLF</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div-->
            </div>
            <div class="row px-3 px-md-0 mt-6">
              <div class="col-12 position-relative">
                <ol class="carousel-indicators">
                  <li class="active" data-bs-target="#carouselExampleDark" data-bs-slide-to="0"></li>
                  <li data-bs-target="#carouselExampleDark" data-bs-slide-to="1"></li>
                  <li data-bs-target="#carouselExampleDark" data-bs-slide-to="2"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

    </section>
</template>

<script>
export default {
    name : 'Testimoni'
}
</script>