<template>
  <div class="contactUs">
    <NavbarOther />
    <Contact />
    <Link />
    <Footer />
  </div>
</template>

<script>
import NavbarOther from '@/components/NavbarOther.vue'
import Contact from '@/components/Contact.vue'
import Link from '@/components/Link.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'ContactUs',
  components: {
    NavbarOther, 
    Contact, 
    Link, 
    Footer
  }
}
</script>