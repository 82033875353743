<template>
    <section id="contact">
        <div class="container pt-0">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-8 text-center mb-3">
              <!--h5 class="text-danger">Layanan</h5-->
              <h2>Hubungi Kami</h2>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-md-6 col-lg-5 col-xl-4">
              <p class="text-700 my-4"> <i class="fas fa-map-marker-alt text-warning me-3"></i><span>Jalan Cik Di Tiro No. 34, Yogyakarta</span></p>
              <p><i class="fas fa-phone-alt text-warning me-3"></i><span class="text-700">+62 8137 0000 475 (Whatsapp)<br/><span class="ps-4">Senin - Jumat : 08.00 - 16.30 WIB </span></span></p>
              <p><i class="fas fa-envelope text-warning me-3"> </i><a class="text-700" href="mailto:jamitu.id@gmail.com"> jamitu.id@gmail.com</a></p>
              <ul class="list-unstyled list-inline mt-5">
                <li class="list-inline-item"><a class="text-decoration-none" href="#!"><i class="fab fa-facebook-square fs-2"></i></a></li>
                <li class="list-inline-item"><a class="text-decoration-none" href="#!"><i class="fab fa-instagram-square fs-2"></i></a></li>
                <li class="list-inline-item"><a class="text-decoration-none" href="#!"><i class="fab fa-twitter-square fs-2"></i></a></li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-5 col-xl-4">
              <form class="row" @submit.prevent="onSubmitMessage">
                <div class="mb-3">
                  <label class="form-label visually-hidden" for="inputName">Nama</label>
                  <input class="form-control form-quriar-control" id="inputName" type="text" v-model="name" placeholder="Nama" />
                </div>
                <div class="mb-3">
                  <label class="form-label visually-hidden" for="inputEmail">Another label</label>
                  <input class="form-control form-quriar-control" id="inputEmail" type="email" v-model="email" placeholder="Email" />
                </div>
                <div class="mb-5">
                  <label class="form-label visually-hidden" for="validationTextarea">Message</label>
                  <textarea class="form-control form-quriar-control is-invalid border-400" id="validationTextarea" v-model="message" placeholder="Pesan" style="height: 150px" required="required"></textarea>
                </div>
                <div class="d-grid">
                  <button class="btn btn-primary btn-lg fw-bold" type="submit">Kirim Pesan<i class="fas fa-paper-plane ms-2"></i></button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Contact',
    data() {
      return {
        name : '',
        email : '',
        message : ''
      };
    },
    methods : {
      onSubmitMessage() {
        axios
          .post(
            `https://member-area.jamitu.id/web_api/contact`,
            { name:this.name, email:this.email, message:this.message },
          )
          .then((response) => {
            console.log(response);
          });
      }
    }
}
</script>