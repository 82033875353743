<template>
    <section class="pt-7 pb-0 bg-white">

        <div class="container">
          <div class="row">
            <div class="col-md-3 mb-5">
              <div class="text-center"><img src="../assets/img/icons/univ.png" alt="..." />
                <h2 class="text-dark-blue mt-4">42</h2>
                <p class="fs--6 text-800">Perguruan Tinggi</p>
              </div>
            </div>
            <div class="col-md-3 mb-5">
              <div class="text-center"><img src="../assets/img/icons/prodi.png" alt="..." />
                <h2 class="text-dark-blue mt-4">600</h2>
                <p class="fs--6 text-800">Program Studi</p>
              </div>
            </div>
            <div class="col-md-3 mb-5">
              <div class="text-center"><img src="../assets/img/icons/unit.png" alt="..." />
                <h2 class="text-dark-blue mt-4">130</h2>
                <p class="fs--6 text-800">Unit/Lembaga</p>
              </div>
            </div>
            <div class="col-md-3 mb-5">
              <div class="text-center"><img src="../assets/img/icons/auditor.png" alt="..." />
                <h2 class="text-dark-blue mt-4">510</h2>
                <p class="fs--6 text-800">Auditor Internal</p>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

    </section>
</template>

<script>
export default {
    name : 'Awards'
}
</script>